@font-face {
    font-family: HansKendrick;
    src: local("Hans Kendrick Bold"),
    local("HansKendrick-Bold"),
    url(assets/fonts/HansKendrick-Bold.ttf),
    url(assets/fonts/HansKendrick-Bold.otf);
    font-weight: bold;
}

@font-face {
    font-family: HansKendrick;
    src: local("Hans Kendrick Medium"),
    local("HansKendrick-Medium"),
    url(assets/fonts/HansKendrick-Medium.ttf),
    url(assets/fonts/HansKendrick-Medium.otf);
    font-weight: 500;
}

@font-face {
    font-family: HansKendrick;
    src: local("Hans Kendrick Regular"),
    local("HansKendrick-Regular"),
    url(assets/fonts/HansKendrick-Regular.ttf);
    font-weight: normal;
}
