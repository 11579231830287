@keyframes auftauchen {
    from {
        opacity: 0;
        transform: translateY(25px);
    }
    30% {
        opacity: 0;
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
